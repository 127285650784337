<template>
  <div class="inteBox">
    <intNavheader></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <!-- <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor1">处方审核</div> -->
        <waitNumber :waitDataTotalNumber='waitDataTotal'></waitNumber>
        <!-- <div class="titleTopCenterLeftBox" @click="gonewDoctor">新增患者</div> -->
      </div>
      <div class="titleTopCenterCenter" style="padding-bottom:20px;height:calc(100vh - 110px);overflow-y: auto;">
        <div class="titleTopCenterCenterTop titleTopCenterCenterTopLine">
          <el-page-header @back="goBack" content="患者详情"> </el-page-header>
          <div class="noflex-l-s" style="margin-top: 40px">
            <div class="titleTopright1 titleSize22" style="width: 20%">
              {{ patientDetail.name }}
            </div>
            <div style="width: 50%">
              <div class="flex-l">
                <div>
                  <span>性别：</span><span>{{ patientDetail.sex ? "男" : "女" }}</span>
                </div>
                <div style="padding: 0 10%">
                  <span>年龄：</span><span>{{ patientDetail.age }}</span>
                </div>
                <div>
                  <span>联系电话：</span><span>{{ patientDetail.phone }}</span>
                </div>
                  <div style="padding: 0 10%">
                  <span>基础病：</span><span>{{ patientDetail.diseaseName }}</span>
                </div>
              </div>
              <div class="flex-l" style="padding: 30px 0">
                <div>
                  <span>身份证号：</span><span>{{ patientDetail.idNo ? patientDetail.idNo:'暂未填写' }}</span>
                </div>
                <div style="padding-left: 10%">
                  <span>住址：</span><span>{{ patientDetail.address ? patientDetail.address:'暂未填写' }}</span>
                </div>
              </div>
              <div class="flex-l" v-if="patientDetail.remake ">
                <div>
                  <span>备注：</span><span>{{ patientDetail.remake ? patientDetail.remake : "暂未填写" }}</span>
                </div>
              </div>
            </div>
            <div style="width: 30%; text-align: right;cursor: pointer;">
              <div class="tagEdit" @click="goedit()">编辑患者信息</div>
              <!-- <div class="tagEdit" style="margin-top:12px">新建病历</div> -->
            </div>
          </div>
        </div>
        <div class="titleTopCenterCenterTop">
          <div>
            <div class="Content1Title">最近诊断</div>
            <div class="flex-l" style="margin-top: 30px; flex-wrap: wrap" v-if="patientDetail.patientDiagnose && patientDetail.patientDiagnose.length > 0">
              <div class="patientimeBox" v-for="(item, index) in patientDetail.patientDiagnose" :key="index" style="margin-top: 20px">
                <div class="flex-l-b">
                  <div>
                    <span class="greenColor">诊断时间：</span>
                    <span>{{ item.addTime }}</span>
                  </div>
                  <div  class="flex-l">
                    <div class="seeBtn2" @click="goseeDetail(item)">初诊病历</div>
                    <div v-if="item.count != 0" class="seeBtn2" style="margin-left:10px" @click="goitemBadge(item)">复诊记录</div>
                  </div>

                </div>
                <div class="patientimeBtom" style="margin-top: 30px">
                  <span class="greenColor">诊断：</span>{{ item.diseaseDiagnose }}
                </div>
                <div v-if="item.count != 0">
                  <el-badge :value="item.count" class="item itemBadge" type="primary" >
                      
                  </el-badge>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="patientimeBox">暂无诊断数据</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="titleTopLine"></div>
    <el-dialog
      title="历史诊断记录"
      :visible.sync="eldialogVisible"
      width="60%">
         <div class="flex-l" style="flex-wrap: wrap" v-if="patientDetail.patientDiagnose && patientDetail.patientDiagnose.length > 0">
          <div :class="index == 0 ? 'patientimeBox2' : 'patientimeBox1'" v-for="(item, index) in visitData" :key="index" style="margin-top: 20px">
            <div class="flex-l-b">
              <div>
                <span class="">{{index == 0 ? '初诊时间' : '复诊时间'}}：</span>
                <span>{{ item.addTime }}</span>
              </div>
              <div :class="index == 0 ? 'seeBtn' : 'seeBtn1'" @click="goseeDetail(item)">查看病历</div>
              <!-- <el-button>查看病历</el-button> -->
            </div>
            <div class="patientimeBtom" style="margin-top: 30px">
              <span class="">{{index == 0 ? '初诊' : '复诊'+ index}}：</span>{{ item.diseaseDiagnose }}
            </div>
          </div>
         </div>
    </el-dialog>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
export default {
  components: {
    intNavheader,
    waitNumber
  },
  data() {
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    return {
      patientDomain: patient,
      drugpatientDomain: drugpatient,
      userInfo: this.$store.state.localData.userInfo,
      eldialogVisible:false,
      patientDetail: {},
      addtoNew: false,
      inputValue: "",
      inputValue2: "",
      ageOptions: [
        {
          value: "岁",
          label: "岁",
        },
        {
          value: "月",
          label: "月",
        },
        {
          value: "天",
          label: "天",
        },
      ],
      num: "",
      patientForm: {
        age: "",
        value: "",
        name: "",
      },
      activeName: "first",
      patientid: 0,
      waitDataTotal:0,
      visitData:[]
    };
  },
  mounted() {
    this.goDoctor();
    this.gettWaitData()
  },
  methods: {
    goitemBadge(e){
      var _this = this
      _this.eldialogVisible = true
      _this.patientDomain.GetPatientType(
        _this.$route.query.id, e.id,
        function (data) {
          _this.visitData = data.data.patientDiagnose
          for (var i = 0; i < data.data.patientDiagnose.length; i++) {
            var f = data.data.patientDiagnose[i].addTime.split("T");
            data.data.patientDiagnose[i].addTime = f[0];
          }
        },
        function (error) {
          console.log(error);
        })
    },
    gettWaitData() {
      var _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
    goDoctor1() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
    },
    goedit() {
      this.$router.push({
        name: "intPatientList",
        query: {
          id: this.patientDetail.id,
          type: 1, //修改
        },
      });
    },
    goBack() {
      this.$router.push({
        name: "intPatientList",
      });
    },
    goDoctor() {
      var _this = this;
      _this.patientDomain.GetPatientDetail(
        _this.$route.query.id,
        function (data) {
          _this.patientDetail = data.data;
          if (_this.patientDetail.patientDiagnose.length > 0) {
            for (var i = 0; i < _this.patientDetail.patientDiagnose.length; i++) {
              var f = _this.patientDetail.patientDiagnose[i].addTime.split("T");
              _this.patientDetail.patientDiagnose[i].addTime = f[0];
            }
          }
        },
        function (error) {
          console.log(error);
          _this.patientDetail = {};
        }
      );
    },
    gonewDoctor() {
      this.addtoNew = !this.addtoNew;
    },
    handleClickTop(e, e1) {
      if (e.label == "患者病历") {
        this.$router.push({
          name: "interrogationIndex",
        });
      }
      if (e.label == "患者列表") {
        this.$router.push({
          name: "intPatientList",
        });
      }
      if (e.label == "个人常用处方") {
        this.$router.push({
          name: "preintPatientList",
        });
      }
      if (e.label == "处方管理") {
        this.$router.push({
          name: "PremangePatientList",
        });
      }
    },
    goseeDetail(item) {
      this.$router.push({
        name: "PatientListseeDtail",
        query: {
          id: item.id,
          patientId: item.patientId,
        },
      });
    },
  },
};
</script>
<style scoped>
.itemBadge {
  position: absolute;
  bottom: 0;
  right: 15px;
}
.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titleTopRight div {
  margin-left: 10px;
}
.titleTopLine {
  width: 100%;
  height: 12px;
  background: #f0f2f5;
}
.titleTopCenter {
  width: 100%;
  height: 100%;
  display: flex;
}
.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  /* min-height: 750px; */
  overflow: auto;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  /* min-height: 750px; */
  background-color: #ffffff;
}
.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 0 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.titleTopCenterCenterTopLine {
  border-bottom: 3px solid #f0f2f5;
}
.flex-l {
  display: flex;
  align-items: center;
}
.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Content1Title {
  padding: 20px 40px;
  padding-left: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0;
  text-align: left;
}
.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}
.ageInput {
  width: 80%;
}
.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}
.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}
.greenColor {
  color: #06add7;
}
.titleSize16 {
  font-size: 16px;
}
.titleSize14 {
  font-size: 14px;
}
.titleSize22 {
  font-size: 22px;
}
.tagEdit {
  margin-left: 60%;
  width: 30%;
  height: 40px;
  border: 1px solid #00afb5;
  color: #00afb5;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
}
.patientimeBox {
  width: 24%;
  padding: 24px 30px;
  background: #fafafa;
  border: 1px solid #cfe5e6;
  border-radius: 8px;
  text-align: center;
  margin-right: 1%;
  cursor: pointer;
  position: relative;
}
.patientimeBox1 {
  width: 42%;
  padding: 24px 30px;
  background: #D0994A;
  /* border: 1px solid #cfe5e6; */
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  margin: 0 1%;
  cursor: pointer;
  position: relative;
  color: #FFFFFF;
  font-weight: bold;
}
.patientimeBox2 {
  width: 42%;
  padding: 24px 30px;
  background: #00AFB5;
  /* border: 1px solid #cfe5e6; */
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  margin: 0 1%;
  cursor: pointer;
  position: relative;
  color: #FFFFFF;
  font-weight: bold;
}
.patientimeBtom {
  text-align: left;
  margin-top: 15px;
}
::v-deep .el-pagination {
  padding: 10px 5px;
}
.el-page-header {
  line-height: 73px;
  /* padding-left: 20px; */
}
.seeBtn {
  font-size: 14px;
  color: #00AFB5;
  padding: 7px 12px;
  border-radius: 2px;
  background: #ffffff;
  line-height: 1;
}
.seeBtn1 {
  font-size: 14px;
  color: #D0994A;
  padding: 7px 12px;
  border-radius: 2px;
  background: #ffffff;
  line-height: 1;
}
.seeBtn2 {
  font-size: 14px;
  color: #FFFFFF;
  padding: 7px 12px;
  border-radius: 2px;
  background: #00AFB5;
  line-height: 1;
}
</style>
